<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple">
            <div class="title_h">
              {{ $t("message.create_order_column") }}
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button
                :loading="loadingButton"
                :disabled="loadingButton ? true : false"
                @click="submit(true)"
                type="success"
                >{{ $t("message.save_and_close") }}</el-button
              >
              <el-button @click="resetForm('form')">{{
                $t("message.close")
              }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="row">
      <div class="col-12 mt-2">
        <div class="modal-body">
          <el-form
            ref="form"
            status-icon
            :model="form"
            :rules="rules"
            :label-position="'left'"
          >
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item :label="columns.name.title" prop="name">
                  <el-input v-model="form.name" autocomplete="off"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-checkbox v-model="form.is_report">{{
                  $t("message.is_report")
                }}</el-checkbox>
              </el-col>
              <el-col :span="24">
                <el-checkbox v-model="form.is_conclusion">{{
                  $t("message.is_conclusion")
                }}</el-checkbox>
              </el-col>
              <el-col :span="24">
                <el-checkbox v-model="form.is_register">{{
                  $t("message.is_register")
                }}</el-checkbox>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import form from "@/utils/mixins/form";
export default {
  mixins: [form],
  data() {
    return {
      checked1: false,
      checked2: false,
      checked3: false,
    };
  },
  created() {
    this.form.doctor_can = true;
  },
  computed: {
    ...mapGetters({
      rules: "orderColumn/rules",
      model: "orderColumn/model",
      columns: "orderColumn/columns",
    }),
  },
  methods: {
    ...mapActions({
      save: "orderColumn/update",
    }),
    submit(close = true) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          console.log(this.form);
          const query = { id: this.form.id, data: this.form };
          this.save(query)
            .then((res) => {
              this.loadingButton = false;
              this.$notify({
                title: "Успешно",
                type: "success",
                offset: 130,
                message: res.message,
              });
              this.resetForm("form");
              this.parent().listChanged();
              this.close();
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$notify({
                title: "Ошибка",
                type: "error",
                offset: 130,
                message: err.error.message,
              });
            });
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.$emit("c-close", { drawer: "drawerUpdate" });
    },
  },
};
</script>